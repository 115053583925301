<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm @submit.prevent="login">
                  <h1>Login</h1>
                  <p class="text-muted">Ingresa a tu cuenta</p>
                  <CInput
                    placeholder="Username"
                    autocomplete="username email"
                    v-model="form.email"
                  >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <CInput
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    v-model="form.password"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CRow v-if="error">
                    <CCol>
                      <CAlert color="danger" dismissible>
                        <strong>Ocurrió un error,</strong> usuario o contraseña incorrecta
                      </CAlert>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol col="5" class="text-left">
                      <CButton color="primary" class="px-4" type="submit">
                        <CSpinner v-if="loading" variant="grow" size="sm"/>
                         Login  
                      </CButton>
                    </CCol>
                    <CCol col="7" class="text-right">
                      <CButton color="link" class="px-0" @click="forgot()">Olvidó su contraseña?</CButton>
                      <CButton color="link" @click="open()">Distribuidor nuevo?</CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody class="banner">
                <img src="img/banner_inicio.jpg" width="100%" />
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { login } from '@/api/auth'


export default {
  data: () => ({
    user: {},
    form: {
      email: '',
      password: ''
    },
    loading: false,
    error: false,
  }),
  mounted() {
    if(this.$store.state.user.token){
      this.$router.push('/commerceInventory');
    }
  },
  methods: {
    async login() {
      this.loading = true;
      await login(this.form).then(response => {
        this.$store.commit('setToken', response.data.token);
        this.$cookies.set('login_token', response.data.token);
        if(response.data.token){
          this.$store.commit('setMenu', response.data.menu);
          this.$store.commit('setRole', response.data.user.role);
          this.$store.commit('setName', response.data.user.name);
        }
        location.reload();
        this.loading = false;
      }).catch(error => {
        this.error = true;
        this.loading = false;
      })
    },
    open() {
      this.$router.push({
        path: "register",
      });
    },
    forgot() {
      this.$router.push({
        path: "reset",
      });
    },
  },
  name: 'Login'
}
</script>
